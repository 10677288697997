<template>
  <div class="issues flex flex-column gap-4">

    <div class="flex items-center justify-end gap-2 w-100">
      <div class="flex gap-2">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="createIssue()"
        >
          Add issue
        </b-button>
      </div>
    </div>

    <div class="font-bold">
      Showing {{ issues.length | format }} issues(s)
    </div>
    <table class="table table-google table-issues">
      <thead>
        <tr>
          <th>ID</th>
          <th>Description</th>
          <th>Periods</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="4">
            <div class="font-bold">
              <span v-if="isLoading">Loading..</span>
              <span v-else-if="issues.length === 0">No issues found</span>
            </div>
          </td>
        </tr>

        <tr v-for="issue in issues" :key="issue.id">
          <td class="row-fit text-right">{{ issue.id }}</td>
          <td>{{ issue.issue_description }}</td>
          <td class="row-fit">
            <div class="d-flex gap-2 justify-end">
              <b-badge
                variant="light"
                v-for="issue_period in issue.issue_periods"
                :key="`issue${issue.id}_period${issue_period}`"
              >
                {{ getPeriodDescription(issue_period) }}
              </b-badge>
            </div>
          </td>
          <td class="row-fit">
            <div class="flex gap-2">
             <b-button
                variant="primary"
                size="sm"
                @click="editIssue(issue)"
              >
                Edit
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="deleteIssue(issue)"
                :disabled="issue.id === 1"
              >
                Delete
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <issue-modal
      v-if="issueModal.showModal"
      :data="issueModal"
      :periods="periods"
      @change="onIssueChange"
    />
  </div>
</template>

<script>
const IssueModal = () => import('@/components/burn_samples/IssueModal.vue');

export default {
  name: 'Issues',
  components: {
    IssueModal,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      issues: [],
      periods: [],
      issueModal: {
        showModal: false,
        issue: null,
      },
    };
  },
  methods: {
    onIssueChange() {
      this.fetchIssues();
    },
    createIssue() {
      this.loadingCount++;
      this.$http
        .post('/burn_sample/issue')
        .then((res) => {
          this.issues.push(res.body.issue);
          this.editIssue(res.body.issue);
        })
        .catch((err) => {
          alert(`Failed to create issue: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    deleteIssue(issue) {
      if (!confirm(`Do you really wish to delete '${issue.issue_description}'?`)) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/burn_sample/issue/${issue.id}`)
        .then(() => {
          this.issues = this.issues.filter((item) => item.id !== issue.id);
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete issue: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    editIssue(issue) {
      this.issueModal.issue = issue;
      this.issueModal.showModal = true;
    },
    getPeriodDescription(periodId) {
      const period = this.periods.find((item) => item.id === periodId);
      return period ? period.period_description : '...';
    },
    fetchIssues() {
      this.loadingCount++;
      this.$http
        .get('/burn_sample/issue')
        .then((res) => {
          this.issues = res.body.issues;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch issues: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchPeriods() {
      this.loadingCount++;
      this.$http
        .get('/burn_sample/period')
        .then((res) => {
          this.periods = res.body.periods;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch periods: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    this.fetchIssues();
    this.fetchPeriods();
  },
};
</script>

<style lang="scss" scoped>
  .issues {
    max-width: 1000px;
  }
</style>
